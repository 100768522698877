import React from "react"
import { graphql } from "gatsby"
import styled from "@emotion/styled"
import Layout from "../components/layout"
import SEO from "../components/seo"
import JustComments from 'gatsby-plugin-just-comments';
import { Twitter } from 'react-social-sharing'
import { Facebook } from 'react-social-sharing'
import { Disqus, CommentCount } from 'gatsby-plugin-disqus'





const Content = styled.div`
  margin: 0 auto;
  max-width: 700px;
  padding: 1.45rem 1.0875rem;
  text-align: justify;
`

const MarkedHeader = styled.h1`
  display: inline;
  border-radius: 1em 0 1em 0;
  background-image: linear-gradient(
    -100deg,
    rgba(255, 250, 150, 0.15),
    rgba(255, 250, 150, 0.8) 100%,
    rgba(255, 250, 150, 0.25)
  );
  font-size: 2rem;
  font-style: normal;
  line-height: 1.5rem;
  font-weight: 300;
  letter-spacing: 0.045rem;
  word-spacing: 0.15rem;
  margin-bottom: 2.5rem;
  margin-top: 2.5rem;
`

const HeaderDate = styled.h3`
  margin-top: 10px;
  color: #606060;
  font-size: 1.0rem;
  font-style: normal;
  line-height: 1.65rem;
  font-weight: 300;
  letter-spacing: 0.045rem;
  word-spacing: 0.15rem;
  margin-bottom: 3rem;
`

// STYLE THE TAGS INSIDE THE MARKDOWN HERE
const MarkdownContent = styled.div`
  a {
    text-decoration: none;
    position: relative;
  }

  a::after {
    content: "";
    position: absolute;
    z-index: -1;
    top: 70%;
    left: -0.1px;
    right: -0.1px;
    bottom: 0;
    transition: top 0.1s ease-in-out;
    background-color: rgba(255, 250, 150, 0.8);
  }

  a:hover::after {
    top: 0;
  }

  p {
    font-size: 1rem;
    font-style: normal;
    line-height: 1.5rem;
    font-weight: 300;
    letter-spacing: 0.045rem;
    word-spacing: 0.15rem;
    margin-bottom: 2.5rem;
    margin-top: 2.5rem;
  }

  h3 {
    font-size: 1.75rem;
    font-style: normal;
    line-height: 1.65rem;
    font-weight: 300;
    letter-spacing: 0.045rem;
    word-spacing: 0.15rem;
    margin-bottom: 3rem;
  }

  ul > li {
    font-size: 1rem;
    font-style: normal;
    line-height: 1.45rem;
    font-weight: 300;
    letter-spacing: 0.0725rem;
    word-spacing: 0.02rem;
    padding-top: 0rem;
    margin-bottom:0rem; 
  }

`

export default ({ data }) => {
  const post = data.markdownRemark
  let disqusConfig = {
    url: "http://devashishmulye.com"+post.frontmatter.path,
    identifier: String(post.frontmatter.id),
    title: post.frontmatter.title,
  }
  console.log("log3",post.frontmatter.featuredImage.childImageSharp.fluid.src)
  return (
    <Layout>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
        twitterImage={post.frontmatter.featuredImage.childImageSharp.fluid.src}
      />
      <Content>
        <MarkedHeader>{post.frontmatter.title}</MarkedHeader>
        <HeaderDate>
          {post.frontmatter.date} - {post.fields.readingTime.text}
        </HeaderDate>
        <MarkdownContent dangerouslySetInnerHTML={{ __html: post.html }} />
        <hr align="center"/>
        <div style={{margin:"auto", marginTop: "1rem"}}>
            <div align="center">
            <p> Share this blog! </p>
            <div style={{display: "flex", flexdirection: "row" , alignContent: "center", justifyContent: "center"}}>
              
              <Twitter solid small link={"devashishmulye.com" + post.frontmatter.path } />
              <Facebook solid small link={"devashishmulye.com" + post.frontmatter.path }/>
            </div>
            </div>


          <hr align="center"/>
          {/* <JustComments apikey="d9cc4344-f7a3-44f3-be9a-4059e9300177" recaptcha="true" hideattribution="true" disableprofilepictures="true"/> */}
          <CommentCount config={disqusConfig} placeholder={''}/>
          <Disqus config={disqusConfig} />
        </div>
      <hr align="center"/>
      </Content>
      </Layout>
  )
}

// might have to use html instead of the react component cuz i cant stryle it. google for how to style the react component of just comment

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      excerpt(pruneLength: 160)
      frontmatter {
        date(formatString: "DD MMMM, YYYY")
        path
        title
        description
        id
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
  }
      fields {
        readingTime {
          text
        }
      }
    }
  }
`
